import React, { useEffect, useState } from 'react';
import './Popus.css'
const Popup = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const closePopup = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div>

      <div className="popup-overlay" id="popup">
        <div className="popup-content">
          <button className="close-btn" onClick={closePopup} style={{ color: 'white' }}>×</button>
        </div>

      </div>
      </div>
    )
  );
};

export default Popup;
